<template>
  <div class="powerManagePage">
      权限管理
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.powerManagePage {
    
}
</style>